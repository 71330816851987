import React from "react";
import { HeadFC, graphql, Link } from "gatsby";
import { Box, Grid, Typography } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Icon } from "@iconify/react";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { PropsBlog, Frontmatter, Node, HeroImage } from "../interfaces";

const Blog = ({ data }: PropsBlog) => {
  const nodes = data?.allMdx?.nodes
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h1" py={3} textAlign="center">
          Explora artículos
        </Typography>
        <Grid container gap={2} justifyContent="center">
          {nodes?.map((post) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={post.frontmatter?.slug} sx={{}}>
                <Link
                  to={post.frontmatter.slug}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <GatsbyImage
                    image={getImage(post.frontmatter.hero_image)!}
                    alt={post.frontmatter.hero_image_alt}
                    style={{ maxHeight: "180px" }}
                  />
                  <Typography variant="h2" py={1}>
                    {post.frontmatter.title}
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    flexWrap="nowrap"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Grid container gap={1}>
                        <Icon icon="ant-design:user-outlined" />
                        <Typography variant="caption">{post.frontmatter.author}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item gap={1}>
                      <Grid container gap={1}>
                        <Icon icon="akar-icons:calendar" />
                        <Typography variant="caption">{post.frontmatter.createdAd}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body1" pt={1}>
                    {`${
                      post.frontmatter.description.length > 160
                        ? post.frontmatter.description.slice(0, 160) + "..."
                        : post.frontmatter.description
                    }`}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Layout>
  );
};

export default Blog;

export const Head: HeadFC = () => <SEO title="Blog - Fibexty"></SEO>;

export const pageQuery = graphql`
  {
    allMdx(
      sort: { order: DESC, fields: frontmatter___createdAd }
      filter: { frontmatter: { published: { eq: "true" } } }
    ) {
      nodes {
        frontmatter {
          createdAd(formatString: "D/MM/YYYY")
          title
          description
          slug
          author
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(formats: WEBP)
            }
          }
        }
      }
    }
  }
`;
