import React from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

interface Props {
  title:string,
  description?: string
  pathname?: string
  children?: any
}

export const SEO = ({ title, description, pathname, children }:Props) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata()
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  }
  
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      {children}
    </>
  )
}